import { renderCdnVersion, renderVersion } from '@app/utils/renderVersion';
import { ecapiUrl } from '../utils/domain-route';
import Fetch from '../utils/jsonp';

interface optionsType {
  signId: string;
  callback: string;
}

async function fetchHeaderMenu(options: optionsType): Promise<any> {
  const { signId = '', callback = 'cb_ecshopCategoryRegion' } = options;
  const strSignId = encodeURIComponent(encodeURIComponent(signId));
  const version = renderVersion({ other: 'v1.6' });
  const cdnVersion = renderCdnVersion({ dev: '', other: 'cdn' });
  const url = ecapiUrl({
    path: `/${cdnVersion}ecshop/cateapi/${version}/sign/${strSignId}/menu&_callback=${callback}`,
  });

  const body = await Fetch.jsonp({
    url,
    callback,
  });
  return body;
}

export { fetchHeaderMenu };
