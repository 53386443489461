// https://ecapi.pchome.com.tw/ecshop/prodapi/v2/prod/button&id=DIBPWP-A900A623A&fields=Seq,Id,Price,Qty,ButtonType,SaleStatus,isPrimeOnly,SpecialQty,Device&_callback=jsonp_button&1665457020?_callback=jsonp_button

import { ecapiUrl } from '../utils/domain-route';
import Fetch from '../utils/jsonp';

interface optionsType {
  prodId: string;
  version: string;
  token?: string;
  prefix?: string;
  fields: string;
}

// online : https://ecapi-cdn.pchome.com.tw/ecshop/prodapi/v2/prod/button&id=DBCP16-1900FUQRK&fields=Seq,Id,Price,Qty,ButtonType,SaleStatus,isPrimeOnly,SpecialQty,Device&_callback=jsonp_button&1674223020?_callback=jsonp_button
// stage : https://ecapi-cdn.pchome.com.tw/ecshop/prodapi/stage/prod/button&id=DBCP16-1900FUQRK&fields=Seq,Id,Price,Qty,ButtonType,SaleStatus,isPrimeOnly,SpecialQty,Device&_callback=jsonp_button&1674223200?_callback=jsonp_button
// preview : https://ecapi2.pchome.com.tw/ecshop/prodapi/v2/preview/prod/button&token=6c8e3fa2e6340aa420a1dacf1ff6ad3667f545ea&id=DIBMZJ-A900FY5Z1&fields=Seq,Id,Price,Qty,ButtonType,SaleStatus,isPrimeOnly,SpecialQty,Device&_callback=jsonp_button&1674223380?_callback=jsonp_button
function fetchProdButton(options: optionsType): Promise<any> {
  const { prodId, version, token, prefix, fields } = options;
  const callback: string = `jsonp_button_${prefix}`;
  let url = '';
  if (version === 'preview') {
    url = `https://ecapi2.pchome.com.tw/ecshop/prodapi/v2/preview/prod/button&token=${token}&id=${prodId}&fields=Seq,Id,Price,Qty,ButtonType,SaleStatus,isPrimeOnly,SpecialQty,Device&_callback=${callback}`;
  } else {
    url = ecapiUrl({
      path: `/ecshop/prodapi/${version}/prod/button&id=${prodId}&fields=${fields}&_callback=${callback}`,
    });
  }

  return Fetch.jsonp({
    url,
    callback,
  });
}
export { fetchProdButton };
