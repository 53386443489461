// https://ecapi-cdn.pchome.com.tw/cdn/marketing/activity/v1/activity&regionid=DEAW&fields=ActId,ActName,Url&_callback=jsonp_activity&2774921
import { ecapiUrl } from '../utils/domain-route';
import Fetch from '../utils/jsonp';

interface optionsType {
  regionId: string;
  version: string;
  callback: string;
}

function fetchActivityMenu(options: optionsType): Promise<any> {
  const { regionId, version, callback } = options;
  const url = ecapiUrl({
    path: `/cdn/marketing/activity/${version}/activity&regionid=${regionId}&fields=ActId,ActName,Url&_callback=${callback}&1`,
  });

  return Fetch.jsonp({
    url,
    callback: `${callback}`,
  }).then((res) => res);
}
export { fetchActivityMenu };
