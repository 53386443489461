// https://ecapi-cdn.pchome.com.tw/cdn/ecshop/recommendapi/v2/clothing/collocation/recommendation?q=${prodId}&_callback=jsonp_collocationrecommend&_callback=jsonp_collocationrecommend
import { ecapiUrl } from '@app/services/utils/domain-route';
import Fetch from '../../utils/jsonp';

interface optionsType {
  version: string;
  callback: string;
  prodId: string;
}

function fetchCollocationRecommend(options: optionsType): Promise<any> {
  const { version, callback, prodId } = options;
  const url = ecapiUrl({
    path: `/cdn/ecshop/recommendapi/${version}/clothing/collocation/recommendation?q=${prodId}&_callback=${callback}`,
  });

  return Fetch.jsonp({
    url,
    callback: `${callback}`,
  }).then((res) => res);
}

export { fetchCollocationRecommend };
