// https://ecapi-cdn.pchome.com.tw/cdn/ecshop/prodapi/v2/prod/DMATI3-1900C3VXF/add&fields=Seq,Id,Name,Spec,Group,Price,Pic,Qty,isWarranty&_callback=jsonp_add?_callback=jsonp_add
import { ecapiUrl } from '../utils/domain-route';
import Fetch from '../utils/jsonp';

interface optionsType {
  prodId: string;
  version: string;
}

function fetchProdAddItem(options: optionsType): Promise<any> {
  const { prodId, version } = options;
  const url = ecapiUrl({
    path: `/cdn/ecshop/prodapi/${version}/prod/${prodId}/add&fields=Seq,Id,Name,Spec,Group,Price,Pic,Qty,isWarranty&_callback=jsonp_add`,
  });

  return Fetch.jsonp({
    url,
    callback: 'jsonp_add',
  }).then((body) => body);
}
export { fetchProdAddItem };
