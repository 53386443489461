import { ecapiUrl } from '../utils/domain-route';
import Fetch from '../utils/jsonp';

interface optionsType {
  regionId: string;
}

function fetchRegionInformation(options: optionsType): Promise<any> {
  const { regionId } = options;
  const url = ecapiUrl({
    path: `/cdn/ecshop/cateapi/v1.5/region&id=${regionId}&_callback=jsonp_regionstyle`,
  });

  return Fetch.jsonp({
    url,
    callback: 'jsonp_regionstyle',
  }).then((body) => body);
}
export { fetchRegionInformation };
