import axios from 'axios';
import '@utils/http-interceptor';
import Fetch from '../utils/jsonp';
import { ecapiUrl } from '../utils/domain-route';

interface optionsType {
  prodId?: string;
  memberId?: string;
  timestamp?: string;
}
interface trackProdListType extends optionsType {
  prefix: string;
}

// 正式：https://ecapi.pchome.com.tw/ecshop/traceapi/v2/prod/QFAIOW-A9009ICRS-000?memberid=treelazy821006%40gmail.com
// stage：https://ecapi2.pchome.com.tw/ecshop/traceapi/stage/prod/QFAIOW-A9009ICRS-000?memberid=treelazy821006%40gmail.com
function fetchPostProdTrackButton(options: optionsType): any {
  const { prodId, memberId } = options;
  const url = ecapiUrl({
    path: `/ecshop/traceapi/v2/prod/${prodId}?memberid=${memberId}`,
    isNormal: true,
  });
  return axios.post(url, null, { withCredentials: true }).then((response) => ({
    ...response,
  }));
}

function fetchDeleteProdTrackButton(options: optionsType): any {
  const { prodId, memberId } = options;
  const url = ecapiUrl({
    path: `/ecshop/traceapi/v2/prod/${prodId}?_method=delete&memberid=${memberId}`,
    isNormal: true,
  });
  return axios.post(url, null, { withCredentials: true }).then((response) => ({
    ...response,
  }));
}
function fetchMemberTrackProdList(options: trackProdListType): any {
  const { memberId, timestamp, prefix = '' } = options;

  const url = ecapiUrl({
    path: `/ecshop/traceapi/v2/user/${memberId}/prod&${timestamp}`,
    isNormal: true,
  });

  return Fetch.jsonp({
    url,
    callback: `jsonp_${prefix}TraceProd`,
  }).then((body: any) => body);
}

export { fetchPostProdTrackButton, fetchDeleteProdTrackButton, fetchMemberTrackProdList };
