import Fetch from '@services/utils/jsonp';
import { ecapiUrl } from '../utils/domain-route';

interface optionsType {
  prodId: string;
  timestamp: string;
  callback: string; // enum types
  fields: string;
  count?: number;
}

// online : https://ecapi-cdn.pchome.com.tw/ecshop/prodapi/v2/prod/DPAC1T-A900AVR5Q-000&fields=Seq,Id,Name,Nick,Store,PreOrdDate,SpeOrdDate,Price,Discount,Pic,Weight,ISBN,Qty,Bonus,isBig,isSpec,isCombine,isDiy,isNC17,isRecyclable,isCarrier,isMedical,isBigCart,isSnapUp,isDescAndIntroSync,isFoodContents,isHuge,isEnergySubsidy,isPrimeOnly,isPreOrder24h,isWarranty,isLegalStore,isFresh,isBidding,isSet,Volume,isArrival24h,isETicket,ShipType,isO2O,RealWH,ShipDay,ShipTag,isEbook,isSubscription,Subscription,isOnSale,PicExtra,YoutubeInfo&_callback=jsonp_prod&2789753?_callback=jsonp_prod
// stage : https://ecapi-cdn.pchome.com.tw/ecshop/prodapi/stage/prod/DIBJJB-A900FVWKT-000&fields=Seq,Id,Name,PicExtra,YoutubeInfo&_callback=jsonp_prod&1673842680?_callback=jsonp_prod
function fetchMultipleProdInformation(options: optionsType): any {
  const { timestamp, prodId, callback, fields, count = 0 } = options;

  const url = ecapiUrl({
    path: `/ecshop/prodapi/v2/prod?id=${prodId}&fields=${fields}&_callback=${callback}&_${timestamp}`,
  });

  return Fetch.jsonp({
    url,
    callback: `${callback + count}`,
  }).then((body) => body);
}
export { fetchMultipleProdInformation };
