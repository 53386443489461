// https://ecapi.pchome.com.tw/ecshop/cateapi/v1.5/sign&site=24h&fields=Id,Name,Sort,Nodes&_callback=jsonpcb_signcate&5604790
import { TBurgerMenuResponse } from '@dfTypes/store/burgerMenu/Response';
import Fetch from '../utils/jsonp';

interface optionsType {
  callback: string;
}

function fetchBurgerMenu(options: optionsType): Promise<TBurgerMenuResponse> {
  const { callback } = options;
  const url = `https://ecapi-cdn.pchome.com.tw/ecshop/cateapi/v1.5/sign&site=24h&fields=Id,Name,Sort,Nodes&_callback=${callback}`;

  return Fetch.jsonp({ url, callback });
}
export default fetchBurgerMenu;
