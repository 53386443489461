// https://ecapi-cdn.pchome.com.tw/cdn/ecshop/prodapi/v2/newarrival/DYBF/prod/count&_callback=jsonp_newarrival
import { ecapiUrl } from '../utils/domain-route';
import Fetch from '../utils/jsonp';

interface optionsType {
  regionId: string;
  version: string;
  callback: string;
}

function fetchNewArrivalRegion(options: optionsType): Promise<any> {
  const { regionId, callback } = options;
  const url = ecapiUrl({
    path: `/cdn/ecshop/prodapi/v2/newarrival/${regionId}/prod/count&_callback=${callback}`,
  });

  return Fetch.jsonp({
    url,
    callback: `${callback}`,
  }).then((res) => res);
}
export { fetchNewArrivalRegion };
