// https://ecapi.pchome.com.tw/ecshop/prodapi/v2/prod?id=DCAIFW-A900AS07H,DDAD07-A9008IWUN,DDAD8M-A900A7PX3,DDAD07-A90095F2E,DYAQGO-A900BPVFC,DDCX1A-A900AZ7SP,DDAD8M-A9008PIB0&fields=Seq,Id,Name,Nick,Store,PreOrdDate,SpeOrdDate,Price,Discount,Pic,Weight,ISBN,Qty,Bonus,isBig,isSpec,isCombine,isDiy,isRecyclable,isCarrier,isMedical,isBigCart,isSnapUp,isDescAndIntroSync,isFoodContents,isHuge,isEnergySubsidy,isPrimeOnly,isPreOrder24h,isWarranty,isLegalStore,isFresh,isBidding,isSet,Volume,isArrival24h,isETicket,ShipType,isO2O,RealWH,ShipDay,ShipTag&_callback=jsonpcb_prodecshop&_callback=jsonpcb_prodecshop
import { LastProdDataResponse } from '@dfTypes/homepage/homepageProd';
import Fetch from '../utils/jsonp';
import { ecapiUrl } from '../utils/domain-route';

interface optionsType {
  version: string;
  callback: string;
  itemList: string[];
}

function fetchLastProd(options: optionsType): Promise<LastProdDataResponse[]> {
  const { version = 'v2', callback = 'jsonpcb_prodecshop', itemList = [] } = options;
  const url = ecapiUrl({
    path: `/ecshop/prodapi/${version}/prod?${
      itemList.join().length > 0 ? `id=${itemList.join()}&` : ''
    }fields=Seq,Id,Name,Nick,Store,PreOrdDate,SpeOrdDate,Price,Pic`,
  });

  return Fetch.jsonp({
    url,
    callback: `${callback}`,
  }).then((body) => body);
}

export { fetchLastProd };
