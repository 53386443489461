// dev : http://ecapi1.dev.mypchome.com.tw/marketing/activity/v1/activity/info?actid=104688

import { TActivityStoreInformationResponse } from '@dfTypes/common/activityStore';
import { ecapiUrl } from '../utils/domain-route';
import Fetch from '../utils/jsonp';

interface optionsType {
  actId: string;
}

// 單品頁&館頁的活動館相關資訊
function fetchActivityStoreInformation(
  options: optionsType
): Promise<TActivityStoreInformationResponse> {
  const { actId } = options;
  const url = ecapiUrl({ path: `/marketing/activity/v1/activity/info?actid=${actId}` });

  return Fetch.jsonp({
    url,
    callback: 'store_activity_info',
  }).then((body) => {
    return body;
  });
}
export default fetchActivityStoreInformation;
