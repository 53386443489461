import { IProdActivityMenuState } from '@dfTypes/sideMenu';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { fetchActivityMenu } from '@services/sideMenu/activityMenu.api';
import { loadingStatus } from '@utils/loadingStatus';
import { RootState } from 'src/store';
import { ecwebUrl } from '@app/services/utils/domain-route';

export enum ProdActivityMenuState {
  Name = 'prodActivityMenu',
}

const initialState: IProdActivityMenuState = {
  activityMenuLoading: loadingStatus.idle,
  activityMenuData: [],
};
const FETCH_ACTIVITY_MENU: string = 'sideMenu/fetchActivityMenu';

// activityMenu
export const getActivityMenu = createAsyncThunk(
  FETCH_ACTIVITY_MENU,
  async (regionId: string, thunkAPI) => {
    try {
      const response = await fetchActivityMenu({
        callback: 'jsonp_activity',
        version: 'v1',
        regionId,
      });

      const nodes = response?.map((item) => ({
        Id: `${item.ActId}`,
        Name: item.ActName,
        Url: `${ecwebUrl({ path: `/store/?fq=/A/${item.ActId}`, isPageUrl: true })}`,
        // gtm code
        gtmItemId: `${item.ActId}`, // 活動Id
        gtmItemName: item.ActName, // 活動名稱
        gtmLocationBlock: 'side_menu',
        gtmLocationNode: 'activity_活動專區',
        gtmName: `${item.ActId}_${item.ActName}`,
      }));
      return [
        {
          Id: `${regionId}_ACT`,
          Name: '活動專區',
          Nodes: nodes,
          Sort: 1,
        },
      ];
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.message.data);
    }
  }
);

const prodActivityMenuSlice = createSlice({
  name: ProdActivityMenuState.Name,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getActivityMenu.pending, (state) => {
        state.activityMenuLoading = loadingStatus.pending;
        state.activityMenuData = [];
      })
      .addCase(getActivityMenu.fulfilled, (state, action) => {
        state.activityMenuLoading = loadingStatus.succeeded;
        state.activityMenuData = action.payload;
      })
      .addCase(getActivityMenu.rejected, (state) => {
        state.activityMenuLoading = loadingStatus.failed;
        state.activityMenuData = [];
      });
  },
});

type ProdActivityMenuSliceReducer = {
  [ProdActivityMenuState.Name]: ReturnType<typeof prodActivityMenuSlice.reducer>;
};

export const prodActivityMenu = (state: RootState<ProdActivityMenuSliceReducer>) =>
  state.prodActivityMenu;
export default prodActivityMenuSlice.reducer;
