import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from 'src/store';
import { fetchSuggestKeyword } from '@services/common/search.api';
import { now } from '@utils/initTime';
import { loadingStatus } from '@app/utils/loadingStatus';
import {
  SearchAutosuggestData,
  SearchAutosuggestResponse,
} from '@dfTypes/header/searchAutosuggest';
import { toAutosuggest } from './transform/searchAutosuggest.transformers';

export enum SearchAutosuggest {
  Name = 'searchAutosuggest',
}
type SearchAutosuggestState = {
  loading: string;
  keyword: string;
  suggestions: SearchAutosuggestData;
  currentFilterSign: { id: string; name: string; url: string };
  isSearchTyping: boolean;
  isKeywordTyping: boolean; // input focus
  isSignFilter: boolean;
};

const initialState: SearchAutosuggestState = {
  loading: loadingStatus.idle,
  keyword: '',
  currentFilterSign: { id: 'h24/all', name: '全站', url: '' },
  suggestions: [],
  isSearchTyping: false,
  isKeywordTyping: false,
  isSignFilter: false,
};
const FETCH_HEADER_SEARCH_AUTOSUGGEST = 'header/search/autosuggest';

export const fetchSearchAutosuggest = createAsyncThunk(
  FETCH_HEADER_SEARCH_AUTOSUGGEST,
  async (obj: { keyword: string; device: string }, thunkAPI) => {
    const { keyword, device } = obj;
    try {
      const response = (await fetchSuggestKeyword({
        searchText: keyword,
        limit: 15,
        timestamp: now.toString(),
      })) as unknown as SearchAutosuggestResponse;

      return toAutosuggest({ response, device });
    } catch (error) {
      return thunkAPI.rejectWithValue(`searchAutosuggest api error\n${error}`);
    }
  }
);
export const searchAutosuggestSlice = createSlice({
  name: SearchAutosuggest.Name,
  initialState,
  reducers: {
    setKeyword: (state, action) => {
      state.keyword = action.payload;
    },
    setIsSearchTyping: (state, action) => {
      state.isSearchTyping = action.payload;
    },
    setIsKeywordTyping: (state, action) => {
      state.isKeywordTyping = action.payload;
    },
    setIsSignFilter: (state, action) => {
      state.isSignFilter = action.payload;
    },
    setCurrentFilterSign: (state, action) => {
      state.currentFilterSign = action.payload;
    },
    setDefaultFilterSign: (state) => {
      state.currentFilterSign = { id: 'h24/all', name: '全站', url: '' };
    },
    clearSuggestions: (state) => {
      state.suggestions = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchSearchAutosuggest.pending, (state) => {
        state.loading = loadingStatus.pending;
      })
      .addCase(fetchSearchAutosuggest.fulfilled, (state, action) => {
        state.suggestions = action.payload;
        state.loading = loadingStatus.succeeded;
      })
      .addCase(fetchSearchAutosuggest.rejected, (state) => {
        state.loading = loadingStatus.failed;
      });
  },
});

type SearchAutosuggestSliceReducer = {
  [SearchAutosuggest.Name]: ReturnType<typeof searchAutosuggestSlice.reducer>;
};
export const {
  setKeyword,
  setIsKeywordTyping,
  setIsSearchTyping,
  setIsSignFilter,
  setCurrentFilterSign,
  setDefaultFilterSign,
  clearSuggestions,
} = searchAutosuggestSlice.actions;
export const searchAutosuggest = (state: RootState<SearchAutosuggestSliceReducer>) =>
  state.searchAutosuggest;
export default searchAutosuggestSlice.reducer;
