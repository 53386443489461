import axios from 'axios';
import '@utils/http-interceptor';
import { ecvipUrl } from '../utils/domain-route';

// 登出
type optionsType = {
  memberId: string;
};

function fetchLogout(options: optionsType): any {
  const { memberId } = options;
  const url = ecvipUrl('/fsapi/member/v1/logout');
  const headerConfig = {
    headers: {
      'content-type': 'application/x-www-form-urlencoded; charset=UTF-8',
    },
    withCredentials: true,
  };
  const postData = { MemberId: memberId };
  return axios.post(url, postData, headerConfig);
}

export { fetchLogout };
