// https://ecapi.pchome.com.tw/imapi/v2.1/member/${MBR}/noticemeta?_callback=jsonpcb_noticeSummary

import Fetch from '../utils/jsonp';

interface optionsType {
  MBR: string;
}

function fetchNoticeSummary({ MBR }: optionsType): Promise<any> {
  const url = `https://ecapi.pchome.com.tw/imapi/v2.1/member/${MBR}/noticemeta?_callback=jsonpcb_noticeSummary`;

  return Fetch.jsonp({
    url,
    callback: 'jsonp_fetchNoticeSummary',
  }).then((body) => body);
}

export default fetchNoticeSummary;
