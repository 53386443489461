// https://ecapi-cdn.pchome.com.tw/cdn/ecshop/stage/region,store?cate=NC17,EntArea,HidePromotion
// https://ecapi-cdn.pchome.com.tw/cdn/ecshop/v2.1/region,store?cate=NC17,EntArea,HidePromotion&_callback=jsonpcb_underage&468385?_callback=jsonpcb_underage
import { ecapiUrl } from '../utils/domain-route';
import Fetch from '../utils/jsonp';

interface optionsType {
  callback: string;
  timestamp: string;
  version: string;
}

export type TFetchUnderAgeResponse = {
  EntArea: string[];
  HidePromotion: string[];
  NC17: string[];
};

function fetchUnderAgeData(options: optionsType): Promise<TFetchUnderAgeResponse> {
  const { callback = 'jsonp_underage_test', timestamp = '', version } = options;
  return Fetch.jsonp({
    url: ecapiUrl({
      path: `/cdn/ecshop/${version}/region,store?cate=NC17,EntArea,HidePromotion&_callback=${callback}&${timestamp}`,
    }),
    callback: `${callback}`,
  }).then((body) => body);
}

export { fetchUnderAgeData };
