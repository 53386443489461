import axios from 'axios';
import '@utils/http-interceptor';
import { ecapiUrl } from '../utils/domain-route';

interface optionsType {
  pageName: string;
  pageId?: string;
}

// 測試站 : http://ecapi2.dev.mypchome.com.tw/cms/contents?pageType=store&pageId=DICN4F
// stage : https://ecapistg.gc.global.mypchome.com.tw/fsapi/cms/contents?pageType=prod&pageId=DGBJHP-A900G0BXG
// online : https://ecapi-cdn.pchome.com.tw/fsapi/cms/contents?pageType=prod&pageId=DGBJHP-A900G0BXG
// content
export function fetchCMSContentInformation(options: optionsType): Promise<any> {
  const { pageName, pageId } = options;
  const url = ecapiUrl({
    path: `/fsapi/cms/contents?pageType=${pageName}${pageId ? `&pageId=${pageId}` : ''}`,
  });

  return axios.get(url).then((response) => response);
}

interface previewOptionType {
  previewType: string;
  previewId: string;
}

// 測試站：http://ecapi2.dev.mypchome.com.tw/cms/contents/preview?previewType=Ad&previewId=20230503190521
// stage : https://ecapistg.gc.global.mypchome.com.tw/fsapi/cms/contents/preview?previewType=Ad&previewId=20230515170541
// online : https://ecapi-cdn.pchome.com.tw/fsapi/cms/contents/preview?previewType=Ad&previewId=20230515170541
// 預覽content API
export function fetchCMSContentPreviewInformation(options: previewOptionType): Promise<any> {
  const { previewType, previewId } = options;

  const url = ecapiUrl({
    path: `/fsapi/cms/contents/preview?previewType=${previewType}&previewId=${previewId}`,
  });

  return axios.get(url).then((response) => response);
}
