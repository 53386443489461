import { IMediaVideoResponse } from '@dfTypes/mediaVideo';
import Fetch from '../utils/jsonp';
import { apiDomain } from '../utils/domain-route';

interface optionsType {
  timestamp: string;
  callback: string;
}

// https://24h.m.pchome.com.tw/cdn/video/collection.htm/data&wt=json&_callback=jsonp_video&1656645243

function fetchVideos(options: optionsType): Promise<IMediaVideoResponse[]> {
  const { timestamp = '', callback = 'jsonp_video' } = options;

  const domain = apiDomain?.video;
  const url = `${domain}/cdn/video/collection.htm/data&wt=json&_callback=${callback}&${timestamp}`;

  return Fetch.jsonp({
    url,
    callback,
  }).then((response) => response);
}

export { fetchVideos };
